@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Red Hat Display', sans-serif;
  @apply antialiased;
}

.bg-glow {
  background-image: url(./assets/Glow-Mobile.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;

  @screen lg {
    background-image: url(./assets/Glow-Desktop.svg);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.animate-pulsating {
  animation: pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0% {
      transform: scale(.4);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}

.animate-spin-slow {
  animation: spin 3s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}


#about-us-background {
  background-image: url(https://images.unsplash.com/photo-1585664811087-47f65abbad64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=80);
  background-size: cover;
  background-position: center;

  @screen md {
    background-image: url(https://images.unsplash.com/photo-1585664811087-47f65abbad64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=900&q=80);
  }
}
